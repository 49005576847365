import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`About`}</h1>
    <p>{`My name is Lirik Brucaj; I am a Computer Science major from Pace University with a minor in Information Technology. I will be graduating with a Bachelors of Science in May 2020. When I'm not programming I like to attend car shows, Play sports, Hike and play some video games time to time. I worked at NBCUniversal as a Full-stack developer/data engineering intern. At NBC, I used ReactJs, Flask, MongoDB, and Python to build a full-stack application and PySpark, Hive, Tableau, Hadoop, PostgreSQL on the data engineering side. I also wrote a data validation automation script in pandas and python. My team of interns also won NBC's 2019 summer intern competition where we designed and built the new NBC `}<a href={`https://mediatech.nbcunicareers.com`}>{`MediaTech`}</a>{` webpage. I was an application developer intern at Metro-North Railroad. At Metro-North, I used ColdFusion Markup Language, MySQL, and Javascript to rebuild an existing inventory program which keeps track of all the parts that they use.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      