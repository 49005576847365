import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Box and Weave" link="https://apps.apple.com/us/app/box-and-weave/id1450119047" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
This project was a fun endless runner arcade game that I created for the Apple App Store.
    </ProjectCard>
    <ProjectCard title="Fino Restaurante" link="https://finocroton.com" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
I created a website for a local italian restaurant which led to an increase in customers coming in.
    </ProjectCard>
    <ProjectCard title="Mobile Web Dev Final Project" link="http://webpage.pace.edu/lb91729p/MobileWebDevFinalProj" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
This is my Final project for my mobile web development class.
    </ProjectCard>
    <ProjectCard title="Everyday Math" link="" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
I created a iphone app with some of the math skills that you use everyday such as discount off calculator, tip calculator.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      